import { helpers } from 'vuelidate/lib/validators';

export const thing_valid = value=> !helpers.req(value) || value.length <= 20;
export const thing_feedback = 'مەزمۇن 20 ھەرىپتىن ئاز بولسۇن';
export const number_valid = value=> !helpers.req(value) || (Number(value) && value.length <= 32);
export const number_feedback = '32 خانىدىن ئاز بولغان سان تولدۇرۇڭ';
let stringPattern = /^[A-Za-z]+$/;
export const letter_valid = value=> !helpers.req(value) || (stringPattern.test(value) && value.length <= 32);
export const letter_feedback = 'مەزمۇن 32 ھەرىپتىن ئاز بولسۇن';
let flagPattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*——|{}【】‘；：”“'。，、？ ]");
export const symbol_valid = value=> !helpers.req(value) || (flagPattern.test(value) && value.length <= 5);
export const symbol_feedback = 'بەلگە تىزىقى 5 خانىدىن تۆۋەن بولسۇن';
let characterStringPattern = new RegExp("[A-Za-z0-9`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*——|{}【】‘；：”“'。，、？ ]");
export const characterstring_valid = value=> !helpers.req(value) || (characterStringPattern.test(value) && value.length <= 32);
export const characterstring_feedback = 'مەزمۇن 32 ھەرىپتىن ئاز بولسۇن';
let timePattern = /^[1-9]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])\s+(20|21|22|23|[0-1]\d):[0-5]\d:[0-5]\d$/;
export const time_valid = value=> !helpers.req(value) || timePattern.test(value);
export const time_feedback = 'ۋاقىتنى توغرا تولدۇرۇڭ، مەسىلەن: 15:01，或：2019年10月1日 15:01';
export const date_valid = value=> !helpers.req(value) || timePattern.test(value);
export const date_feedback = 'چىسىلانى توغرا تولدۇرۇڭ، مەسىلەن: 2019年10月1日，或：2019年10月1日 15:01';
let amountPattern = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?[元]?$)/;
export const amount_valid = value=> !helpers.req(value) || amountPattern.test(value);
export const amount_feedback = 'پۇل سوممىسىنى توغرا تولدۇرۇڭ';
let phoneNumberPattern = /((\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$)/;
export const phone_number_valid = value=> !helpers.req(value) || phoneNumberPattern.test(value);
export const phone_number_feedback = 'تېلفۇن نۇمۇرىنى توغرا تولدۇرۇڭ';

//车牌号验证方法
function isVehicleNumber(vehicleNumber) {
  let x_reg=/^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}(([0-9]{5}[DF]$)|([DF][A-HJ-NP-Z0-9][0-9]{4}$))/;
  let c_reg=/^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1}$/;
  if(vehicleNumber.length === 7){
    return c_reg.test(vehicleNumber);
  } else if(vehicleNumber.length === 8){
    return x_reg.test(vehicleNumber);
  } else{
    return false;
  }
}
export const car_number_valid = value=> !helpers.req(value) || isVehicleNumber(value);
export const car_number_feedback = 'ماشىنا نۇمۇر تاختىسىنى توغرا تولدۇرۇڭ';

let namePattern = /([\u4e00-\u9fa5]{1}[\u4e00-\u9fa5.·。]{3,10}[\u4e00-\u9fa5]{1}$)|([a-zA-Z]{1}[a-zA-Z\-]{3,10}[a-zA-Z]{1,20}$)|(\D{1,20}$)/;
export const name_valid = value=> !helpers.req(value) || namePattern.test(value);
export const name_feedback = 'ئىسىمنى توغرا تولدۇرۇڭ، خەنزۇچە ئىسىم 10 خەتتىن، ئېنگىلىزچە ئىسىم 20 ھەرىپتىن ئېشىپ كەتمىسۇن';
let phrasePattern = /\p{Unified_Ideograph}/u;
export const phrase_valid = value=> !helpers.req(value) || (phrasePattern.test(value) && value.length <= 5);
export const phrase_feedback = '5 خەتتىن تۆۋەن بولغان خەنزۇچە خەت تولدۇرۇڭ';
